<template>
  <div class="c-library__announcements">
    <button class="c-library__announcements-button" :class="{'c-library__announcements-button_active': active== 2022}" @click="setActive(2022)">2022</button>
    <button class="c-library__announcements-button" :class="{'c-library__announcements-button_active': active== 2023}" @click="setActive(2023)">2023</button>
    <button class="c-library__announcements-button" :class="{'c-library__announcements-button_active': active== 2024}" @click="setActive(2024)">2024</button>
    <button class="c-library__announcements-button" :class="{'c-library__announcements-button_active': active== 2025}" @click="setActive(2025)">2025</button>
  </div>
</template>

<script>
  export default {
    name: 'Filter Year Announcements',
    data() {
      return {
        screenWidth: window.innerWidth,
        active: 2022,
      }
    },
    computed:{
    },
    methods: {
      setActive(element) {
        this.active = element;
      },
      resetActive(){
        this.active = '';
      },
    }
  }
</script>
