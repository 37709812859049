<template>
  <div class="c-library">
    <div class="c-library__title">
      <h2 class="c-library__title-subtitle">Stay up to date with</h2>
      <h1 class="c-library__title-text c-library__title-announcements">Sparrow's latest announcements</h1>
    </div>
    <div class="c-library__description" :class="{'c-library__description_nofilter': !filterActive}">
      <p class="c-library__description-text">Remain in the know of what’s new in Sparrow and find related press materials. </p>
    </div>

    <FilterYearAnnouncements v-if="filterActive"></FilterYearAnnouncements>
    <Card></Card>

  </div>
</template>
<script>
  import Card from '@/components/library/announcements/AnnouncementsCard.vue'
  import FilterYearAnnouncements from '@/components/library/announcements/FilterYearAnnouncements.vue'

  export default {
    name: 'AnnouncementsContent',
    components: {
      Card,
      FilterYearAnnouncements
    },
    data() {
      return {
        filterActive: false,
      }
    },
  }
</script>
