<template>
  <div class="c-library__body c-library__body_announcements" @mouseleave = "resetActive">

    <div class="c-library__card c-library__card_announcements c-library__card_announcement-new-width" v-for="(data,index) in cardData.body" :key="index" @click='directAnnouncementDetail(index)'>
      <div class="c-library__card-container c-library__card-container_announcements" >
        <div class="c-library__card-content c-library__card-content_announcements" >
          <p class="c-library__card-date c-library__card-date_margin-less">{{data.date}}</p>
          <h3 class="c-library__card-desc">{{data.title}}</h3>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import json from '../../../../public/file/announcement.json'
  export default {
    name: 'AnnouncementsCard',
    data() {
      return {
        screenWidth: window.innerWidth,
        cardData: json
      }
    },
    computed:{
    },
    methods: {
      setActive(element) {
        this.active = element;
      },
      resetActive(){
        this.active = '';
      },
      directAnnouncementDetail(index){
        ///library/announcements-detail
        //alert(index);
        this.$store.commit("setAnnouncementId", index);
        this.$router.push({ name: 'Announcements Detail Page'});
      }
    }
  }
</script>
